
export default function ErrorPage() {
  return (
    <div className="fullpage">
      <div className="container">
          <h1>Página não encontrada!</h1>
          <p>Retorne à <a href="/">página inicial</a>.</p>
      </div>
    </div>
  );
}
